export class VerifyEmployee {
  constructor({
    employee_id,
    employee_id_min,
    employee_id_max,
    employee_id_pattern,
  }) {
    if (employee_id === false) {
      throw new Error("Sorry, Employee registration is not required.");
    }
    this.employee_id_min = employee_id_min;
    this.employee_id_max = employee_id_max;
    this.employee_id_pattern = employee_id_pattern;
  }

  validateMinMaxValues(employeeNumber) {
    if (!employeeNumber) {
      throw new Error(`Employee number is required`);
    }
    employeeNumber = employeeNumber.trim();
    if (!this.employee_id_min && !this.employee_id_max) {
      return true;
    }
    if (this.employee_id_min) {
      if (employeeNumber.length < this.employee_id_min) {
        throw new Error(`Minimum character length is ${this.employee_id_min}`);
      }
    }
    if (this.employee_id_max) {
      if (employeeNumber.length > this.employee_id_max) {
        throw new Error(`Maximum character length is ${this.employee_id_max}`);
      }
    }
    return true;
  }

  validateRegExPattern(employeeNumber) {
    if (!employeeNumber) {
      throw new Error(`Employee number is required`);
    }
    employeeNumber = employeeNumber.trim();
    if (!this.employee_id_pattern) {
      return true;
    }
    const regex = new RegExp(this.employee_id_pattern, "g");
    const valid = regex.test(employeeNumber);
    if (!valid) {
      throw new Error("Employee number doesn't match expected pattern.");
    }
    return true;
  }
}
