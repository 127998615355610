<template>
  <div>
    <div class="row mt-5 voucher-home">
      <div class="col-12 col-lg-6 offset-lg-3 dual-box text-white p-4">
        <b-overlay :show="loader" rounded="sm">
          <h3 class="mt-2">Please confirm your email address</h3>

          <div class="async-error" v-if="this.error">
            {{ this.errorMessage }}
          </div>

          <p>
            This is to ensure you receive your voucher to the correct address
          </p>

          <div class="mb-3">
            <div class="row">
              <div class="col">
                <span class="form-control">{{ email }}</span>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col">
                <b-form @submit.stop.prevent="onSubmitYes">
                  <button
                    class="btn btn-light btn-lg text-hpcolour-green btn-block"
                  >
                    Yes
                  </button>
                </b-form>
              </div>
              <div class="col">
                <b-form @submit.stop.prevent="onSubmitNo">
                  <button
                    class="btn btn-light btn-lg text-hpcolour-green btn-block"
                  >
                    No
                  </button>
                </b-form>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import * as CampaignAccessCache from "./../modules/CampaignAccessCache";
import { VerifyCampaignDetails } from "./../modules/VerifyCampaignDetails";
import { VerifyEmployee } from "./../modules/VerifyEmployee";
import voucherFactory from "./../modules/AssignVoucher";

export default {
  mixins: [validationMixin],
  data() {
    return {
      /*form: {
        email: null,
        confirmEmail: null,
      },*/
      email: localStorage.hpFluEmail,
      error: false,
      errorMessage: null,
      loader: false,
      submitted: false,
    };
  },
  validations: {
    /*form: {
      email: {
        required,
        email,
      },
      confirmEmail: {
        required,
        email,
        sameAs: sameAs(function () {
          return this.form.email;
        }),
      },
    },*/
  },
  methods: {
    async onSubmitYes() {
      this.error = false;
      this.loader = true;
      const campaign =
        await CampaignAccessCache.getCampaignAccessCacheByCodeCheckVoucherLimit(
          this.$route.params.code
        );

      if (campaign === null) {
        // if campaign doesn't exist exit
        return;
      }

      try {
        const verifyCampaign = new VerifyCampaignDetails(campaign);

        const organisationActive = verifyCampaign.isOrganisationActive();
        if (!organisationActive) {
          throw new Error("This organisation is not active");
        }
        // console.log(campaign, "campaign");

        // const expired = verifyCampaign.hasExpired();
        // if (expired) {
        //   throw new Error("Sorry, campaign has expired");
        // }
        // console.log(expired, "expired");

        const vouchers = verifyCampaign.vouchersAvailable();
        if (vouchers <= 0) {
          throw new Error(
            "Sorry, your organisation have reached their voucher limit."
          );
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.error = true;
        this.loader = false;
        console.log(e);
        return;
      }

      if (campaign.employee_id) {
        try {
          const verifyEmployee = new VerifyEmployee(campaign);
          verifyEmployee.validateMinMaxValues(localStorage.hpFluEmployeeNumber);
          verifyEmployee.validateRegExPattern(localStorage.hpFluEmployeeNumber);
        } catch (e) {
          this.errorMessage = e;
          this.error = true;
          this.loader = false;
          console.log(e);
          return;
        }
      }

      try {
        const additionalParameters = {
          employee_number: localStorage.hpFluEmployeeNumber || null,
          post_code: localStorage.hpFluPostcode || null,
          email: localStorage.hpFluEmail || null,
          message_type: "send",
        };
        Object.assign(campaign, additionalParameters);
        const voucher = await voucherFactory(
          campaign.email,
          campaign,
          "assignVoucher"
        );

        if (voucher.code) {
          this.$router.push({
            name: "Complete",
            params: { code: voucher.access_code },
          });
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.error = true;
        this.loader = false;
        console.log(e);
      }
    },

    async onSubmitNo() {
      this.$router.push({
        name: "Home",
      });
    },

    /*
    async onSubmit() {
      this.error = false;
      this.submitted = true;
      //this.$v.form.$touch();
      //if (this.$v.form.$anyError) {
      //  return;
      //}

      this.loader = true;

      const campaign =
        await CampaignAccessCache.getCampaignAccessCacheByCodeCheckVoucherLimit(
          this.$route.params.code
        );

      if (campaign === null) {
        // if campaign doesn't exist exit
        return;
      }

      try {
        const verifyCampaign = new VerifyCampaignDetails(campaign);

        const organisationActive = verifyCampaign.isOrganisationActive();
        if (!organisationActive) {
          throw new Error("This organisation is not active");
        }
        // console.log(campaign, "campaign");

        const expired = verifyCampaign.hasExpired();
        if (expired) {
          throw new Error("Sorry, campaign has expired");
        }
        // console.log(expired, "expired");

        const vouchers = verifyCampaign.vouchersAvailable();
        if (vouchers <= 0) {
          throw new Error(
            "Sorry, your organisation have reached their voucher limit."
          );
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.error = true;
        return;
      }

      if (campaign.employee_id) {
        try {
          const verifyEmployee = new VerifyEmployee(campaign);
          verifyEmployee.validateMinMaxValues(localStorage.hpFluEmployeeNumber);
          verifyEmployee.validateRegExPattern(localStorage.hpFluEmployeeNumber);
        } catch (e) {
          this.errorMessage = e;
          this.error = true;
          this.loader = false;
          return;
        }
      }

      try {
        const additionalParameters = {
          employee_number: localStorage.hpFluEmployeeNumber || null,
          post_code: localStorage.hpFluPostcode || null,
          message_type: "send",
        };
        Object.assign(campaign, additionalParameters);
        const voucher = await voucherFactory(
          campaign,
          this.form.email, // <<<
          "assignVoucher"
        );

        if (voucher.code) {
          this.$router.push({
            name: "Complete",
            params: { code: voucher.access_code },
          });
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.error = true;
        this.loader = false;
      }
    },
    */

    /*
    validateState(name) {
      // Filter
      if (this.form.email) {
        this.form.email = this.form.email.toLowerCase().trim();
      }

      if (this.form.confirmEmail) {
        this.form.confirmEmail = this.form.confirmEmail.toLowerCase().trim();
      }

      switch (name) {
        case "email":
          var { $dirty, $error } = this.$v.form[name];
          break;
        case "confirmEmail":
          var { $dirty, $error } = this.$v.form[name];
          break;
        default:
        // code block
      }
      return $dirty ? !$error : null;
    },
    */
  },
};
</script>

<style scoped>
.async-error {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 150%;
  color: #dc3545;
}
</style>