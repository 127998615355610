import moment from "moment";
export class VerifyCampaignDetails {
  constructor({
    code,
    organisation_active,
    voucher_count,
    start_date,
    end_date,
    employee_id,
  }) {
    this.code = code.toLowerCase().trim();
    this.organisation_active = organisation_active;
    this.voucher_count = voucher_count;
    this.start_date = start_date;
    this.end_date = end_date;
    this.employee_id = employee_id;
  }

  isOrganisationActive() {
    return this.organisation_active ? true : false;
  }
  hasExpired() {
    const startDate = moment(this.start_date, "YYYY-MM-DD HH:mm"),
      endDate = moment(this.end_date, "YYYY-MM-DD HH:mm"),
      isBetween = moment().isBetween(startDate, endDate);
    return !isBetween;
  }
  vouchersAvailable() {
    return this.voucher_count;
  }
  renderEmployeePage() {
    return this.employee_id ? true : false;
  }
}
